<template>
    <inner-page-card class="mb-5" heading="Reports">
        <s-form class="row fl-eq-c" @submit="onSubmit" :key="formKey">
            <div class="col-12 col-lg-9">
                <validated-vue-select @blur="setParams" label="Select Report" v-model="reportType" :options="getOptions()"
                                      :rules="{required: true}" :disabled="loading"
                                      placeholder="Please Select an option to download report"
                                      class="ml-2 c-input-select-b c-input-z-idx order-4 order-lg-2"/>
            </div>
            <div class="col-12 col-lg-9" v-if="reportType === '/api/auth/report/ventura-customer-list/'">
                <validated-vue-select label="Real Estate Agent" v-model="real_estate_agent" :options="realEstateOptions"
                                      :rules="{required: false}" :disabled="loading" placeholder=""
                                      class="ml-2 c-input-select-b c-input-z-idx order-4 order-lg-2"/>
            </div>
            <div class="col-12 col-lg-2">
                <btn text="Download" :loading="loading" loading-text="Downloading..." class="px-3"
                     design="basic-a" color="secondary" icon="fa fa-download"></btn>
            </div>
        </s-form>
    </inner-page-card>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name : 'Report',

    computed : {
        ...mapGetters(['currentUser'])
    },

    data () {
        return {
            realEstateOptions : [
                {
                    label : 'Yes',
                    value : true
                },
                {
                    label : 'No',
                    value : false
                }
            ],
            formKey : 0,

            reportType        : '',
            real_estate_agent : false,
            loading           : false

        };
    },

    methods : {
        setParams () {
            if (this.reportType !== '/api/auth/report/ventura-customer-list/') {
                this.real_estate_agent = false;
            }
        },
        onSubmit () {
            const that = this;

            that.loading = true;

            axios.get(that.reportType, { params : { real_estate_agent : that.real_estate_agent } })
                .then(function (response) {
                    const json = response.data;
                    if (json.permission === false) {
                        that.$notify('Permission Denied, Please log in and try again.', 'Unauthorized',
                            {
                                type : 'danger'
                            }
                        );
                        that.$router.push('/login/').then(() => {
                            window.location.reload(true);
                        });
                        return;
                    }
                    that.loading = false;
                    that.updateForm();
                    that.forceDownload(json.url);
                }).catch(function (exception) {
                    that.loading = false;
                    console.log('exception : ', exception);
                    that.$notify('Server Error', 'Error', { type : 'danger' });
                });
        },

        forceDownload (url) {
            window.open(url);
        },

        updateForm () {
            this.formKey++;
        },

        getOptions () {
            const options = [];

            if (this.currentUser.is_superuser) {
                options.push(
                    { label : 'Bank/FI’s', value : urls.reports.bankList },
                    { label : 'Bank e Auctions India.com Customer', value : urls.reports.venturaCustomerList },
                    { label : 'Auctions', value : urls.reports.auctionsList },
                    { label : 'Completed and Ended Auctions', value : urls.reports.completedAndEndedAuctionsList }
                );
            } else {
                options.push(
                    { label : 'Upcoming Auction', value : urls.reports.auctionDetail },
                    { label : 'Completed and Ended Auctions', value : urls.reports.completedAndEndedAuctionsList }
                );
                if (this.currentUser.is_bank_admin) {
                    options.push(
                        { label : 'User Details', value : urls.reports.userDetail }
                    );
                }
            }

            return options;
        }
    }
};
</script>

<style scoped>

</style>
